import { Box, Button, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Image from "mui-image";
import { useNavigate } from "react-router-dom";
import icon from "../assets/logo/Icon-app.png";
import CategoriesSelect from "./Dropdowns/CategoriesSelect";
import { useAuth } from "../contexts/AuthContext";
import { useEffect } from "react";

export const CategoryStack = () => {
  const { globalFilters } = useAuth();
  const navigate = useNavigate();
  const visitCategory = (category: string) => {
    navigate({
      pathname: "/search-page",
      search: "category=" + category,
    });
  };

  useEffect(() => {}, [globalFilters]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          border: "1px solid grey",
          mb: 2,
          p: 2,
          justifyContent: "center",
        }}
      >
        <CategoriesSelect />
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            overflowX: "auto",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": { width: 1 },
          }}
        >
          {globalFilters!.categories.map((item: any, i: any) => {
            return (
              <>
                <Button
                  key={item}
                  // sx={{ minWidth: 200 }}
                  onClick={() => {
                    visitCategory(item);
                  }}
                >
                  <Stack
                    key={item}
                    alignItems="center"
                    justifyContent="center"
                    direction="row"
                    spacing={2}
                  >
                    {/* TODO: Replace icon */}
                    <Image key={i} src={icon} height={20} width={20} />

                    <Typography variant="subtitle2" color="#000">
                      {item}
                    </Typography>
                  </Stack>
                </Button>
              </>
            );
          })}
        </Box>
      </Box>
    </>
  );
};
