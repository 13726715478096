import { Delete, Mail } from "@mui/icons-material";
import { Avatar, IconButton, ListItemAvatar, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { deleteMessage } from "../../api/messagesApi";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
export default function MessagesList({ messages }: FCMessagesProps) {
  
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const deleteMessageMutation = useMutation(deleteMessage, {
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries("myMessages");
      enqueueSnackbar("Succesfully deleted message", {
        variant: "success",
        preventDuplicate: true,
      });
    },
    onError: (err) => {
      enqueueSnackbar("Unable to delete message", {
        variant: "error",
        preventDuplicate: true,
      });
    },
  });

  return (
    
    <Box sx={{ flexGrow: 1, maxWidth: 1500, height: "100%" }}>
      <Grid container>
        {messages.length == 0 ? (
          <Grid item>
            <Typography>You currently have no messages</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <List>
              {messages.map((message) => {
                return (
                  <ListItem
                    key={message.id}
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete" onClick={()=> deleteMessageMutation.mutate(message.id)}>
                        <Delete />
                      </IconButton>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Mail fontSize="medium" />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontWeight: 500,
                        // color: "primary.main",
                      }}
                      primary={message.content}
                      secondary={`From ${message.sender_name}`}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
