import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  Grid,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Pagination,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import axiosClient from "../api/axiosClient";
import { DownloadHeader, ListingCard } from "../components";

import { Email, ExpandMore, Flag, VerifiedUser } from "@mui/icons-material";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getMyProducts } from "../api/listingsApi";
import NoProducts from "../components/Error/NoProducts";
import Loading from "../components/Loading";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}
const ViewSellerShop = () => {
  let { vendor } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const CustomExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [seller, setSeller] = useState<FCSeller>({});
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };
  function getSeller() {
    return axiosClient
      .get(`/api/organisation/${vendor}`)
      .then((res: any) => {
        setSeller(res.data);
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar("Unable to fetch seller information ", {
          variant: "error",
          preventDuplicate: true,
        });
      });
  }

  useEffect(() => {
    getSeller();
  }, [vendor]);

  const {
    isLoading,

    status,
    error,
    data: myProducts,
    isFetching,
    isPreviousData,
  } = useQuery(["myProducts", page], () => getMyProducts(vendor, page - 1), {
    keepPreviousData: true,
    // refetchOnWindowFocus: true,
  });

  // Add pagination
  return (
    <Box sx={{ height: "100%", minHeight: "100vh" }}>
      <DownloadHeader />
      {loading ? (
        <Loading />
      ) : (
        <Stack
          pt={1}
          spacing={2}
          direction="column"
          alignItems="center"
          // justifyContent="center"
          sx={{ height: "100%" }}>
          {/* <HeaderText text={seller.name + "'s shop"} /> */}
          <Grid
            container
            spacing={3}
            sx={{ width: "100vw", height: "100%", minHeight: "100vh", p: 3 }}
            justifyContent="center"
            alignItems="center">
            <Grid xs={12} px={2}>
              <Card sx={{ width: "100%" }}>
                <CardMedia
                  component="img"
                  alt="image"
                  height="150"
                  image={`${
                    seller?.imageURL ??
                    "https://source.unsplash.com/random?fabrics"
                  }`}
                  sx={{ objectFit: "contain" }}
                />
                <CardContent>
                  <Typography variant="h3">{seller.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {seller.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <CustomExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more">
                    <ExpandMore />
                  </CustomExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Grid container>
                      <Grid xs={8}>
                        <List
                          sx={{
                            width: "100%",
                            maxWidth: 360,
                            bgcolor: "background.paper",
                          }}>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <Email />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary="Email"
                              secondary={seller.email}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <Flag />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary="Country"
                              secondary={seller.countryCode}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <VerifiedUser />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary="Joined at"
                              secondary={dayjs(`${seller.createdAt}`).format(
                                "LL"
                              )}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid>{/* <Typography>Badges</Typography> */}</Grid>
                    </Grid>
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
            {isLoading || isFetching ? (
              <Loading />
            ) : status === "error" ? (
              <div>Unable to fetch seller. Please try again</div>
            ) : myProducts!.content.length !== 0 ? (
              myProducts!.content.map((product, i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2.4}
                    key={i}
                    justifyContent="center"
                    alignItems="center">
                    <ListingCard listing={product} />
                  </Grid>
                );
              })
            ) : (
              <NoProducts />
            )}
            <Grid item xs={12}></Grid>
            <Box component="span">
              <Pagination
                count={myProducts?.totalPages ?? 0}
                page={page}
                onChange={handlePageChange}
                defaultPage={1}
                color="secondary"
                size="large"
                showFirstButton
                showLastButton
                sx={{ justifyContent: "center", padding: "10px" }}
              />
            </Box>
          </Grid>
        </Stack>
      )}
    </Box>
  );
};

export default ViewSellerShop;
