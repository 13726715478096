import { LinkedIn, Instagram } from "@mui/icons-material";
import { Grid, IconButton, Link, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Image from "mui-image";
import logo from "../../assets/logo/logo-orange.png";
import { aboutUrl, contactUsUrl } from "../../utils/Strings/Socials";
import {
  FooterHeaderText,
  FooterLinkText,
  linkStyle,
} from "../Text/TextVariants";
// TODO: Translation
const FooterLink = [{ title: "", to: "" }];

export default function StickyFooter() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "10vh",
        minWidth: "100vw",
      }}>
      <Box
        component="footer"
        sx={{
          mt: "auto",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: 1,
            borderBottom: "1px solid #dfe0df",
            height: "80px",
          }}>
          <Image src={logo} height={30} fit="contain" />
        </Box>

        {/* <Stack
          sx={{ width: "100vw", my: 2 }}
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            direction="row"
            spacing={1}
            divider={
              <Divider
                sx={{ backgroundColor: "#fff" }}
                orientation="vertical"
                flexItem
              />
            }
          >
            <FooterLinkText to={"/our-story"}>Our Story</FooterLinkText>
            <FooterLinkText to={"/more-information"}>
              More Information
            </FooterLinkText>
            <FooterLinkText to={"/faq"}>FAQ</FooterLinkText>
            <FooterLinkText to={"/contact-us"}>Contact us</FooterLinkText>
          </Stack>
        </Stack> */}
        <Stack
          sx={{ width: "100vw", backgroundColor: "#fff", py: 2 }}
          justifyContent="center"
          alignItems="center">
          {/* Switch to grid */}
          <Grid
            sx={{ paddingX: "40px" }}
            direction={{ xs: "row" }}
            spacing={{ xs: 2, md: 8 }}
            p={1}
            justifyContent="center"
            alignItems="center"
            container>
            <Grid item xs={6} md={2}>
              <Stack spacing={2}>
                <FooterHeaderText>Buyers</FooterHeaderText>

                <Stack>
                  <FooterLinkText to={"/buyers-guide"}>
                    How does it work?
                  </FooterLinkText>
                  <FooterLinkText to={"/terms-and-conditions"}>
                    Terms & conditions
                  </FooterLinkText>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={6} md={2}>
              <Stack spacing={2}>
                <FooterHeaderText>Sellers</FooterHeaderText>

                <Stack>
                  <FooterLinkText to={"/sellers-guide"}>
                    How does it work?
                  </FooterLinkText>
                  <FooterLinkText to={"/terms-and-conditions"}>
                    Terms & conditions
                  </FooterLinkText>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={6} md={2}>
              <Stack spacing={2}>
                <FooterHeaderText>Help</FooterHeaderText>

                <Stack>
                  <Link sx={linkStyle} href={contactUsUrl}>
                    Contact us
                  </Link>
                  <FooterLinkText to={"/faq"}>FAQ</FooterLinkText>
                  <FooterLinkText to={"/privacy"}>
                    Privacy Statement
                  </FooterLinkText>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={6} md={2}>
              <Stack spacing={2}>
                <FooterHeaderText>Become a partner</FooterHeaderText>

                <Stack>
                  <Link sx={linkStyle} href={contactUsUrl}>
                    Collaborate with us
                  </Link>
                  <Link sx={linkStyle} href={contactUsUrl}>
                    Advertisement
                  </Link>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={6} md={2}>
              <Stack spacing={2}>
                <FooterHeaderText>About Fabric Connector</FooterHeaderText>

                <Stack>
                  <Link sx={linkStyle} href={aboutUrl}>
                    About us
                  </Link>
                  <Link sx={linkStyle} href="/blog">
                    Blog
                  </Link>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={6} md={2}>
              <Stack spacing={2}>
                <FooterHeaderText>Socials</FooterHeaderText>

                <Stack direction="row">
                  {/* <LinkedIn /> */}
                  <IconButton
                    href="https://www.linkedin.com/company/the-fabric-connector"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ p: 0 }}>
                    <LinkedIn sx={{ color: "primary.dark" }} />
                  </IconButton>
                  {/* <Instagram /> */}
                  <IconButton
                    href="https://www.instagram.com/fabricconnector"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ p: 0 }}>
                    <Instagram sx={{ color: "primary.dark" }} />
                  </IconButton>
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          {/* <Copyright /> */}
        </Stack>
      </Box>
    </Box>
  );
}
