import { Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { lazy, useState } from "react";
import { useTranslation } from "react-i18next";

const NewestGallery = lazy(() => import("./Gallery/NewestGallery"));
const PopularGallery = lazy(() => import("./Gallery/PopularGallery"));

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ p: 3, display: "flex" }}
          alignItems="center"
          justifyContent="center"
        >
          {children}
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// TODO: TYPES
export default function ProductTabs(currentProduct: FCProduct) {
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          // TabIndicatorProps={{ sx: { display: "none" } }}
          centered
          sx={{ justifyContent: "space-around " }}
        >
          <Tab
            sx={{ fontSize: "14px" }}
            label={"Description"}
            {...a11yProps(0)}
          />
          <Tab
            sx={{ fontSize: "14px" }}
            label={"Additional info"}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      {/* Replace with indivual components */}
      <TabPanel value={value} index={0}>
        <Typography>{currentProduct.description}</Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container>
          <Grid item xs={6}>
            <List>
              <ListItem>
                <ListItemText
                  primary={currentProduct.options.materials.join(",")}
                  secondary="Material"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={currentProduct.options.compositions.join(",")}
                  secondary="Composition"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={currentProduct.options.weight + " g/m2"}
                  secondary="Weight"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={currentProduct.options.width + " cm"}
                  secondary="Width"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={currentProduct.options.length + " m"}
                  secondary="Length"
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6}>
            <List>
              <ListItem>
                <ListItemText
                  primary={currentProduct.options.patterns.join(",")}
                  secondary="Pattern"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={currentProduct.options.designs}
                  secondary="Design"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={currentProduct.options.fibers.join(",")}
                  secondary="Fiber"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={currentProduct.options.usages.join(",")}
                  secondary="End Use"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={currentProduct.options.countryCode}
                  secondary="Country of Origin"
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </TabPanel>
    </Box>
  );
}
