import { Dialog, DialogContent, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import Image from "mui-image";
import * as React from "react";
import example from "../assets/example/ExampleImage.png";

export interface DialogProps {
  open: boolean;
  onClose: () => void;
}
const steps = [
  {
    label: "Image Guidelines",
    description: (
      <Stack sx={{ mt: 2, mb: 1 }} alignItems="center" spacing={2}>
        <Typography textAlign="center">
          Hi! Before you upload pictures of the fabric, here is a quick guide on
          how to do that professionally. Take the following steps:
        </Typography>
        <Stack alignItems="center">
          <Typography>1. Make sure there is enough (natural) light</Typography>
          <Typography>
            2. Make sure the texture of the fabric is visible
          </Typography>
          <Typography>
            3. Photograph only the fabric, not the surrounding
          </Typography>
        </Stack>
      </Stack>
    ),
  },
  {
    label: "Additional tips",
    description: (
      <Stack sx={{ mt: 2, mb: 1 }} alignItems="center" spacing={2}>
        <Typography textAlign="center">Tip:</Typography>
        <Stack alignItems="center" sx={{ maxWidth: 300 }} spacing={2}>
          <Typography>
            Folding the fabric can help to show its texture
          </Typography>
          <Typography>
            Adding a pen or your hand can help to show the size of a pattern
          </Typography>
        </Stack>
      </Stack>
    ),
  },
  {
    label: "Example",
    description: (
      <Stack sx={{ mt: 2, mb: 1 }} alignItems="center" spacing={2}>
        <Typography textAlign="center">Here is an example:</Typography>
        <Image src={example} height={300} width={300} />
      </Stack>
    ),
  },
];

export default function UploadStepper(props: DialogProps) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const { open, onClose } = props;

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    // TODO: Dialog
    <>
      <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((step, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={step.label} {...stepProps}>
                    <StepLabel {...labelProps}>{step.label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={onClose} color="inherit">
                    Close
                  </Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {steps[activeStep].description}

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      Skip
                    </Button>
                  )}
                  <Button onClick={handleNext} color="inherit">
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
