import {
  Dropzone,
  ExtFile,
  FileCard,
  FileMosaic,
  FileMosaicProps,
  FullScreen,
  ImagePreview,
  VideoPreview,
} from "@files-ui/react";
import { useState } from "react";
import { MAX_PRODUCT_IMAGE_SIZE } from "../constants";
import { handleImageUpload } from "../../utils/Uploading";
import { fileToBase64 } from "file64";

const BASE_URL = "https://www.myserver.com";

export default function AdvancedDropzoneDemo({
  setb64Images,
  setOpenUpload,
}: {
  setb64Images: (b64Images: any) => void;
  setOpenUpload: (open: boolean) => void;
}) {
  const [b64Images, setB64Images] = useState<any>([]);
  const [extFiles, setExtFiles] = useState<ExtFile[]>([]);
  const [imageSrc, setImageSrc] = useState<File | string | undefined>(
    undefined
  );
  const [videoSrc, setVideoSrc] = useState<File | string | undefined>(
    undefined
  );
  const updateFiles = (incommingFiles: ExtFile[]) => {
    setExtFiles(incommingFiles);
  };
  const onDelete = (id: FileMosaicProps["id"]) => {
    setExtFiles(extFiles.filter((x) => x.id !== id));
  };
  const handleSee = (imageSource: File | string | undefined) => {
    setImageSrc(imageSource);
  };
  const handleWatch = (videoSource: File | string | undefined) => {
    setVideoSrc(videoSource);
  };
  const handleStart = async (filesToUpload: ExtFile[]) => {
    setb64Images([]);
    for (let i = 0; i < filesToUpload.length; i++) {
      console.log(filesToUpload[i].file!);
      const compressedImage = await handleImageUpload(filesToUpload[i].file!);
      const b64 = await fileToBase64(compressedImage);
      setb64Images((currentArray: any) => [
        ...currentArray,
        {
          fileContentBase64: b64.split(",")[1],
          fileName: `image ${i}`,
        },
      ]);
    }
  };
  const handleFinish = (uploadedFiles: ExtFile[]) => {
    setOpenUpload(false);
    console.log("advanced demo finish upload", uploadedFiles);
  };
  const handleAbort = (id: FileMosaicProps["id"]) => {
    setExtFiles(
      extFiles.map((ef) => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: "aborted" };
        } else return { ...ef };
      })
    );
  };
  const handleCancel = (id: FileMosaicProps["id"]) => {
    setExtFiles(
      extFiles.map((ef) => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: undefined };
        } else return { ...ef };
      })
    );
  };
  return (
    <>
      <Dropzone
        onChange={updateFiles}
        minHeight="195px"
        accept="image/*"
        value={extFiles}
        maxFiles={5}
        maxFileSize={MAX_PRODUCT_IMAGE_SIZE}
        label="Drag'n drop files here or click to browse"
        // accept=".png,image/*, video/*"
        uploadConfig={{
          // autoUpload: true
          url: BASE_URL + "/file/28048465460",
          cleanOnUpload: true,
        }}
        onUploadStart={handleStart}
        onUploadFinish={handleFinish}
        fakeUpload
        actionButtons={{
          style: { color: "red" },
          position: "after",
          deleteButton: {},
          uploadButton: { label: "Submit" },
        }}
      >
        {extFiles.map((file) => (
          <FileMosaic
            {...file}
            key={file.id}
            onDelete={onDelete}
            onSee={handleSee}
            onWatch={handleWatch}
            onAbort={handleAbort}
            onCancel={handleCancel}
            resultOnTooltip
            alwaysActive
            preview
            info
          />
        ))}
      </Dropzone>
      <FullScreen
        open={imageSrc !== undefined}
        onClose={() => setImageSrc(undefined)}
      >
        <ImagePreview src={imageSrc} />
      </FullScreen>
      <FullScreen
        open={videoSrc !== undefined}
        onClose={() => setVideoSrc(undefined)}
      >
        <VideoPreview src={videoSrc} autoPlay controls />
      </FullScreen>
    </>
  );
}
