import { MoreVert } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  ListItem,
  Menu,
  Stack,
  Toolbar,
} from "@mui/material";
import Image from "mui-image";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../assets/logo/logo-orange.png";
import logoMobile from "../../assets/logo/logo-mobile.png";
import { useAuth } from "../../contexts/AuthContext";
import Login from "../Auth/Login";
import SignUp from "../Auth/Register";
import LanguageSelect from "../Dropdowns/LanguageSelect";
import SearchSelect from "../Dropdowns/SearchSelect";
import { Search, StyledInputBase } from "../Text/Elements";
import LinkButton, { LinkButtonBlack } from "../LinkButton";

const btnstyles = {
  "&.MuiButton-root": {
    border: "2px white solid",
  },
  "&.MuiButton-text": {
    color: "grey",
  },
  "&.MuiButton-contained": {
    color: "yellow",
  },
  "&.MuiButton-outlined": {
    color: "white",
  },
};

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const { setAuth, organisation } = useAuth();
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);
  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;

    setSearchTerm(text);
  };
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const submit = (event: any) => {
    event.preventDefault();
    if (searchTerm.length == 0) {
      search.delete("query");
      setSearch(search, {
        replace: true,
      });
      navigate(`/search-page`);
    } else {
      search.set("query", searchTerm);
      setSearch(search, {
        replace: true,
      });
      navigate(`/search-page?query=${searchTerm}`);
    }
  };
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <ListItem>
        <SignUp white />
      </ListItem>
      <ListItem>
        <Login white />
      </ListItem>
      <ListItem>
        <LinkButtonBlack href="/about-us">About us</LinkButtonBlack>
      </ListItem>

      {/* <LanguageSelect /> */}
    </Menu>
  );

  return (
    <>
      <AppBar
        elevation={0}
        sx={{ borderBottom: "1px solid #dfe0df", py: "0.5em" }}>
        <Toolbar>
          <Stack
            alignItems="center"
            justifyContent="space-around"
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
              "&&": {
                ml: { xs: "0", md: "-16px" },
              },
            }}>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <Link to="/">
                <Image src={logoMobile} height={40} fit="contain" />
              </Link>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "block", marginLeft: "-16px" },
              }}>
              <Link to="/">
                <Image src={logo} height={40} fit="contain" />
              </Link>
            </Box>
            <Box>
              <Stack
                sx={{
                  flexGrow: 1,
                  border: "1px solid",
                  borderRadius: "5px",
                  borderColor: "black",
                  paddingRight: "0.5em",
                }}
                justifyContent="center"
                alignItems="center"
                direction="row"
                spacing={0.2}>
                <SearchSelect />
                <form onSubmit={submit}>
                  <Search
                    direction="row-reverse"
                    sx={{
                      borderLeft: "1px solid",
                      borderColor: "black",
                    }}>
                    <IconButton type="submit">
                      <SearchIcon color="inherit" />
                    </IconButton>

                    <StyledInputBase
                      onChange={onSearchChange}
                      placeholder={t("Search.1")}
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search>
                </form>
              </Stack>
            </Box>

            <Stack
              direction="row"
              spacing={1}
              sx={{ display: { xs: "none", md: "flex" } }}>
              <SignUp white />
              <Login white />
              <LinkButtonBlack href="/about-us">About&nbsp;us</LinkButtonBlack>
              <LanguageSelect />
            </Stack>

            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                color: "primary.main",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit">
                <MoreVert sx={{ fontSize: 35, color: "primary.main" }} />
              </IconButton>
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  );
};

export default Navbar;
