import { Close, ContentCopy } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import LinkedinIcon from "react-share/lib/LinkedinIcon";
import LinkedinShareButton from "react-share/lib/LinkedinShareButton";

interface ShareDialogProps {
  open: boolean;
  onClose: () => void;
  shareURL: string;
  title: string;
}

const ShareDialog = ({
  open,
  onClose,
  shareURL = "http://github.com",
  title = "GitHub",
}: ShareDialogProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [copied, setCopied] = useState(false);
  const [copyValue, setCopyValue] = useState("");
  const onCopy = useCallback(() => {
    setCopied(true);
    enqueueSnackbar("Succesfully copied link to clipboard", {
      variant: "info",
      preventDuplicate: true,
    });
  }, []);

  useEffect(() => {
    setCopyValue(shareURL);
  }, []);
  return (
    <>
      <Dialog open={open} sx={{ minWidth: 600 }} fullWidth={true}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Share{" "}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>{" "}
        </DialogTitle>
        <DialogContent>
          <Stack
            spacing={2}
            alignItems="center"
            direction="column"
            sx={{ width: "100%" }}
          >
            <Stack direction="row" spacing={2}>
              <LinkedinShareButton url={shareURL}>
                <LinkedinIcon size={50} round />
              </LinkedinShareButton>

              <WhatsappShareButton url={shareURL} title={title} separator=":: ">
                <WhatsappIcon size={50} round />
              </WhatsappShareButton>

              <FacebookShareButton url={shareURL} quote={title}>
                <FacebookIcon size={50} round />
              </FacebookShareButton>

              <TwitterShareButton url={shareURL} title={title}>
                <TwitterIcon size={50} round />
              </TwitterShareButton>
            </Stack>
            <TextField
              fullWidth
              id="outlined-read-only-input"
              defaultValue={shareURL}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <CopyToClipboard onCopy={onCopy} text={copyValue}>
                    <IconButton>
                      <ContentCopy />
                    </IconButton>
                  </CopyToClipboard>
                ),
              }}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShareDialog;
