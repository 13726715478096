import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useTranslation } from "react-i18next";
import axiosClient from "../../api/axiosClient";

export default function ConfirmationToken() {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [token, setToken] = React.useState("example@mail.com");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToken(event.target.value);
  };

  // password changing method
  const onSubmit = () => {
    return axiosClient
      .get(`/api/auth/confirm?token=${token}`)
      .then((res) => {
        enqueueSnackbar(t("Account has been verified successfully.1"), {
          variant: "success",
          preventDuplicate: true,
        });

        setOpen(false);
        // getAccountInfo();
      })

      .catch((err) => {
        enqueueSnackbar(t("Unable to verify account.1"), {
          variant: "error",
          preventDuplicate: true,
        });
      });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        sx={{ textTransform: "none" }}
        variant="contained"
        color="primary"
        size="small"
        onClick={handleClickOpen}
      >
        {t("Confirmation token.1")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{t("Submit token.1")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(
              "To gain access to your account, fill in the token you have received.1"
            )}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={t("Confirmation token.1")}
            type="email"
            fullWidth
            variant="standard"
            value={token}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel.1")}</Button>
          <Button onClick={onSubmit}>{t("Submit.1")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
