import { Avatar, Button, Grid, Stack, Typography } from "@mui/material";
import { DropzoneDialog } from "mui-file-dropzone";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AccountSubText } from "..";
import axiosClient from "../../api/axiosClient";
import { useAuth } from "../../contexts/AuthContext";
import CreateOrganisation from "../Forms/CreateOrganisation";
import EditAccountInfo from "../Forms/EditAccountInfo";
// TODO: Query mutation
import UpdatePasswordDialog from "../Forms/UpdatePassword";
import { useMutation, useQueryClient } from "react-query";
import { uploadProfilePicture } from "../../api/userApi";
import { resizeFile } from "../../utils/Uploading";

const AccountCard = ({ name, email, phone, imageURL }: FCAccountProps) => {
  const [open, setOpen] = useState(false);
  const { organisation } = useAuth();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [b64Image, setB64Image] = useState<FCImage | null>(null);
  const [files, setFiles] = useState([]);
  const queryClient = useQueryClient();
  const deleteAccountEmail = () => {
    const emailAddress = "info@fabric-connector.com";
    const subject = "Delete Account: " + email;
    const body =
      "Hello,\n\nI want to delete my account. Please delete my account and all my data.\n\nBest regards,\n\n";

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  };

  const convert2base64 = async (files: any) => {
    try {
      for (let i = 0; i < files.length; i++) {
        const image = await resizeFile(files[i]);
        onSubmit(image);
      }
    } catch (error) {}
  };

  const updateUserPFMutation = useMutation(uploadProfilePicture, {
    onSuccess: () => {
      queryClient.invalidateQueries("userInfo");
      setOpen(false);
    },
    onError: () => {},
  });

  const onSubmit = async (imageData: string) => {
    updateUserPFMutation.mutate({ fileContent: imageData, fileName: "image" });
  };

  useEffect(() => {}, [b64Image]);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          display: "flex",

          justifyContent: "space-around",
        }}
      >
        <Avatar
          sx={{
            width: 250,
            height: 250,
            border: "solid",
            borderColor: "secondary.main",
          }}
          src={`${imageURL}`}
          imgProps={{ sx: { objectFit: "scale-down" } }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems={{ xs: "center", md: "flex-start" }}
          spacing={3}
        >
          <Stack spacing={2}>
            <Typography
              color="#4d4b4b"
              sx={{
                fontWeight: 500,
                fontSize: 20,
              }}
            >
              {t("Name.1")}
            </Typography>
            <Stack>
              <AccountSubText text={name} />
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Typography color="#4d4b4b" variant="h6">
              {t("Contact details.1")}
            </Typography>
            <Stack>
              <AccountSubText text={email} />
              <AccountSubText text={phone} />
            </Stack>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack
          direction="column"
          spacing={6}
          sx={{ maxWidth: { md: "200px" } }}
          alignItems={{ xs: "center", md: "flex-start" }}
        >
          <EditAccountInfo />
          <Button
            sx={{ textTransform: "none" }}
            variant="contained"
            color="secondary"
            onClick={() => setOpen(true)}
          >
            Upload Image
          </Button>

          <UpdatePasswordDialog />
          {!organisation && <CreateOrganisation />}

          <Button
            variant="contained"
            color="error"
            onClick={deleteAccountEmail}
          >
            Delete Account
          </Button>
        </Stack>
      </Grid>
      <DropzoneDialog
        fileObjects={files}
        acceptedFiles={["image/*"]}
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        filesLimit={1}
        maxFileSize={5000000}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          if (files[0] != undefined) {
            convert2base64(files);
          }
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
      />
    </Grid>
  );
};

export default AccountCard;
