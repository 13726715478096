import { type } from "os";
import imageCompression from "browser-image-compression";
import Resizer from "react-image-file-resizer";

// convert image file to base64 string
export const resizeFile = (file: File) =>
  new Promise<string>((resolve) => {
    Resizer.imageFileResizer(
      file,
      200,
      200,
      "WEBP",
      66,
      0,
      (uri: any) => {
        if (typeof uri === "string") {
          resolve(uri.split(",")[1]);
        } else {
          resolve(uri);
        }
      },
      "base64"
    );
  }).catch((err) => {
    console.log(err);
    return "";
  });

// handle image upload
export function handleImageUpload(file: File) {
  return new Promise<File>((resolve, reject) => {
    console.log("originalFile instanceof Blob", file instanceof Blob); // true
    console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1280,
      useWebWorker: true,
      fileType: "image/webp",
    };

    imageCompression(file, options)
      .then((compressedFile) => {
        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        ); // true
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ); // smaller than maxSizeMB

        resolve(file);
      })
      .catch((error) => {
        console.log(error.message);
        reject(error);
      });
  });
}
