// TODO: Confirmation animation checkmark

import { Verified } from "@mui/icons-material";
import { Button, Card, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

// TODO; /confirm
const Confirm = () => {
  const navigate = useNavigate();
  return (
    <Grid
      container
      sx={{ height: "100%", width: "100%", minHeight: "100vh" }}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={false} alignItems="center" justifyContent="center">
        <Card
          sx={{ width: "80vw", height: "50vh", maxHeight: 800, maxWidth: 800 }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ width: "100%", height: "100%" }}
            spacing={3}
          >
            <Verified sx={{ fontSize: 40 }} color="success" />
            <Typography variant="h6" textAlign="center">
              {" "}
              You account has been verified.
            </Typography>
            <Button onClick={() => navigate("/")}>Head to the Home page</Button>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Confirm;
