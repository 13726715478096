import { Lock, Mail } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box, Container, Divider, FormControl, Grid, InputAdornment, Link, Stack
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import * as yup from "yup";
import axiosClient from "../../api/axiosClient";
import { useAuth } from "../../contexts/AuthContext";
import ConfirmationToken from "./ConfirmationToken";
import ResendConfirmation from "./ResendConfirmation";
import { useTranslation } from "react-i18next";

const btnstyles = {
  "&.MuiButton-root": {
    border: "2px white solid",
  },
  "&.MuiButton-text": {
    color: "grey",
  },
  "&.MuiButton-contained": {
    color: "yellow",
  },
  "&.MuiButton-outlined": {
    color: "white",
  },
};
const LOGIN_URL = "api/auth/login";
const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

const LoginForm = () => {
  const { setAuth, getAccountInfo } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { t, i18n } = useTranslation();

  useEffect(() => {}, []);

  return (
    <Container component="main" maxWidth="md">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              maxWidth="sm"
            >
              <Formik
                validationSchema={LoginSchema}
                initialValues={{
                  email: "",
                  password: "",
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  const data = {
                    password: values.password,
                    email: values.email,
                  };

                  const response = await axiosClient
                    .post(LOGIN_URL, data)
                    .then((res) => {
                      getAccountInfo();
                      localStorage.setItem("auth_token", res.data.token);
                      // enqueueSnackbar(t("Successfully signed in.1"), {
                      //   variant: "success",
                      //   preventDuplicate: true,
                      // });
                      setOpen(false);
                    })
                    .catch((error) => {
                      enqueueSnackbar(error.response.data.message, {
                        variant: "error",
                        preventDuplicate: true,
                      });
                    });
                }}
              >
                {({
                  values,
                  errors,
                  isSubmitting,
                  isValidating,
                  submitForm,
                }) => (
                  <Form>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        name="email"
                        type="email"
                        label="Email"
                        margin="normal"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Mail color="primary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        type="password"
                        label={t("Password.1")}
                        name="password"
                        margin="normal"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Lock color="primary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <LoadingButton
                      variant="contained"
                      fullWidth
                      size="large"
                      loading={isSubmitting || isValidating}
                      sx={{
                        marginTop: "1rem",
                        backgroundColor: "secondary.main",
                        color: "#fff",
                      }}
                      type="submit"
                    >
                      {t("Sign in.1")}
                    </LoadingButton>
                  </Form>
                )}
              </Formik>
              <Grid container sx={{ m: 2 }}>
                <Grid item xs>
                  <Link href="/forgot_password" variant="body2">
                    {t("Forgot password?.1")}
                  </Link>
                </Grid>
              </Grid>
              <Divider
                variant="middle"
                sx={{
                  color: "#627a70",
                  width: "80%",
                }}
              >
                OR
              </Divider>
              <Stack
                direction="row"
                spacing={4}
                alignItems="center"
                justifyContent="center"
                sx={{ width: "100%" }}
              >
                {/* TODO: Add resend confirmation dialog */}

                <ConfirmationToken />
                <ResendConfirmation />
              </Stack>
              {/* TODO: Add social icons */}
            </Box>
          </Container>
  );
};

export default LoginForm;
