// Name: App component
// Description: Routing component of the application, contains all routes

// Import packages
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import loadable from "@loadable/component";
import { Grow } from "@mui/material";
import { SnackbarProvider } from "notistack";
import TagManager from "react-gtm-module";
import RequireAuth from "./components/Auth/RequireAuth";
import CloseSnackbarAction from "./components/CloseSnackbar";
import AppContextProvider from "./contexts/AppContext";
import { AuthProvider } from "./contexts/AuthContext";
import AppLayout from "./pages/AppLayout";
import BulkAdd from "./pages/BulkAdd";
import Confirm from "./pages/Confirm";
import ForgotPassword from "./pages/ForgotPassword";
import Homepage from "./pages/Homepage";
import Layout from "./pages/Layout";
import Listing from "./pages/Listing";
import ResetPassword from "./pages/ResetPassword";
import ViewSellerShop from "./pages/ViewSellerShop";

// Public routes
const About = loadable(() => import("./pages/Aboutpage")); // Information about Fabric Connector
const Account = loadable(() => import("./pages/Account")); // Page for managing account
const ContactSeller = loadable(() => import("./pages/ContactSeller")); // Page for contacting a seller
const Favourites = loadable(() => import("./pages/Favourites")); // Page for showing and managing favourites
const Membership = loadable(() => import("./pages/Membership")); // Page for managing membership
const Missing = loadable(() => import("./pages/Missing")); // Page to show when a route is not found
const NotificationOverview = loadable(() =>
  import("./pages/NotificationOverview")
); // Overview of notifications for users
const Shop = loadable(() => import("./pages/Shop")); // Shop overview page
const Search = loadable(() => import("./pages/Search")); // Search page for products
const Unauthorized = loadable(() => import("./pages/Unauthorized")); // Page to show when user is not authorized to view a page

// Routes in footer
const Advertisement = loadable(() => import("./pages/Footer/Advertisement")); // Link to the contact section of the /about-us page TODO
const Blog = loadable(() => import("./pages/Footer/Blog")); // Blog overview page
const BuyersGuide = loadable(() =>
  import("./pages/Footer/BuyersGuide/BuyersGuide")
); // Guide for buyers
const Contact = loadable(() => import("./pages/Footer/Contact")); // Link to the contact section of the /about-us page
const Collaboration = loadable(() => import("./pages/Footer/Collaboration")); // Link to the contact section of the /about-us page
const FAQ = loadable(() => import("./pages/Footer/FAQ")); // Frequently asked questions
const ReadBlog = loadable(() => import("./pages/Footer/ReadBlog")); // Page for reading blog posts
const SellersGuide = loadable(() => import("./pages/Footer/SellersGuide")); // Guide for sellers / organisations
const Privacy = loadable(() =>
  import("./pages/Footer/PrivacyStatement/PrivacyStatement")
); // Privacy statement
const TermsConditions = loadable(() =>
  import("./pages/Footer/TermsConditions")
); // Terms and conditions

// Private routes
const Dashboard = loadable(() => import("./pages/Private/Dashboard")); // Admin dashboard
const EditBlog = loadable(() => import("./pages/Private/EditBlog")); // Page for editing blog posts

/**
 * App component
 *
 * @returns {JSX.Element}
 *
 */
function App() {
  // Initialize Google Tag Manager on first render
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "G-E0YQ23BGC1",
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  // Return the app jsx
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      style={{ whiteSpace: "nowrap", flexWrap: "nowrap" }}
      action={(key) => <CloseSnackbarAction id={key} />}
      TransitionComponent={Grow}
      maxSnack={3}>
      <AuthProvider>
        <AppContextProvider>
          <BrowserRouter>
            <Routes>
              <Route element={<Layout />}>
                {/* Public routes */}
                <Route element={<AppLayout />}>
                  <Route path="/" element={<Homepage />} />
                  <Route
                    path="product-page/:vendor/:productid"
                    element={<Listing />}
                  />
                  <Route
                    path="view-shop/:vendor"
                    element={<ViewSellerShop />}
                  />
                  <Route path="search-page" element={<Search />} />
                  <Route path="advertisement" element={<Advertisement />} />
                  <Route path="about-us" element={<About />} />
                  <Route path="blog" element={<Blog />} />
                  <Route path="buyers-guide" element={<BuyersGuide />} />
                  <Route path="collaboration" element={<Collaboration />} />
                  <Route path="contact-us" element={<Contact />} />
                  <Route path="faq" element={<FAQ />} />
                  <Route path="read-blog" element={<ReadBlog />} />
                  <Route path="sellers-guide" element={<SellersGuide />} />
                  <Route
                    path="terms-and-conditions"
                    element={<TermsConditions />}
                  />

                  {/* Protected routes */}
                  <Route element={<RequireAuth allowedRoles={[]} />}>
                    <Route path="my-account" element={<Account />} />
                    <Route path="my-favourites" element={<Favourites />} />
                    <Route
                      path="notifications"
                      element={<NotificationOverview />}
                    />
                    <Route
                      path="contact-seller/:vendor/:productid"
                      element={<ContactSeller />}
                    />
                  </Route>

                  {/* Roles routes */}
                  <Route element={<RequireAuth allowedRoles={[]} />}>
                    <Route path="my-shop" element={<Shop />} />
                    <Route path="my-membership" element={<Membership />} />
                    <Route path="bulk-update" element={<BulkAdd />} />
                  </Route>

                  {/* Admin routes */}
                  <Route element={<RequireAuth allowedRoles={["admin"]} />}>
                    <Route path="admin" element={<Dashboard />} />
                    <Route path="admin/edit-blog" element={<EditBlog />} />
                  </Route>
                </Route>

                {/* Utility routes */}
                <Route
                  path="change_password/:token"
                  element={<ResetPassword />}
                />
                <Route path="confirm" element={<Confirm />} />
                <Route path="forgot_password" element={<ForgotPassword />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="unauthorized" element={<Unauthorized />} />
                <Route path="*" element={<Missing />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AppContextProvider>
      </AuthProvider>
    </SnackbarProvider>
  );
}

export default App;
