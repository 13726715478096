import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";
import ListingCard from "../Cards/ListingCard";

function filterByLiked(product: FCProduct) {
  if (product.likes > 0) {
    return true;
  }

  return false;
}

const FavouritesGallery = ({ products }: FCProductsProps) => {
  const navigate = useNavigate();
  const { setCurrentProduct } = useAppContext();
  const onSelected = (product: FCProduct) => {
    setCurrentProduct(product);
    navigate("/product-page/" + product.id);
  };
  return (
    <Grid container spacing={3} sx={{ width: "100vw" }}>
      {products.filter(filterByLiked).map((product, i) => {
        return (
          <Grid item md={2.4} key={i}>
            <ListingCard listing={product} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FavouritesGallery;
