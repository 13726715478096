import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NoProducts = () => {
  const { t, i18n } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        // alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Typography>{t("No products found.1")}</Typography>
    </Box>
  );
};

export default NoProducts;
