import { useSnackbar } from "notistack";
import React, { useContext, useState } from "react";
import axiosClient from "../api/axiosClient";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState(null);
  const [organisation, setOrganisation] = useState(null);
  const [token, setToken] = useState({});
  const [globalFilters, setGlobalFilters] = useState({
    colors: [],
    compositions: [],
    designs: [],
    fibers: [],
    materials: [],
    patterns: [],
    usages: [],
    currencies: [],
    categories: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  async function getAccountInfo() {
    return await axiosClient
      .get(`api/user/current`)
      .then((res) => {
        //TODO: TYPE SAFETY
        setAuth(res.data);

        setOrganisation(res.data.organisation);
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   enqueueSnackbar("You have been logged out", {
        //     variant: "error",
        //     preventDuplicate: true,
        //   });
        // } else {
        //   enqueueSnackbar(err.message, {
        //     variant: "error",
        //     preventDuplicate: true,
        //   });
        // }
      });
  }

  async function getFilters() {
    return await axiosClient
      .get(`/api/organisation/1/listing/options`)
      .then((res) => {
        setGlobalFilters(res.data);
      })
      .catch((err) => {});
  }

  function logout() {
    localStorage.removeItem("auth_token");
    setAuth(null);
    // navigate("/login");
    enqueueSnackbar("You have been logged out", {
      variant: "error",
      preventDuplicate: true,
    });
  }

  const value = {
    auth,
    setAuth,
    getAccountInfo,
    token,
    setToken,
    logout,
    organisation,
    globalFilters,
    getFilters,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
