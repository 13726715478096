// export const materialsList = [
//     "Knit fabrics",
//     "Woven fabrics",
//     "Velvet fabrics",
//     "Corduroy fabrics",
//     "Crepe fabrics",
//     "Elastic fabrics",
//     "Denim fabrics",
//     "Satin fabrics",
//     "Pique fabrics",
//     "Twill fabrics",
//     "Suede fabrics",
//     "Sequin fabrics",
//     "Interlining",

import countryList from "react-select-country-list";

//   ];
export const materialsList = [
  "Corduroy",
  "Crepe",
  "Denim",
  "Elastic",
  "Interlining",
  "Knit",
  "Pique",
  "Satin",
  "Sequin",
  "Suede",
  "Twill",
  "Velvet",
  "Woven",
];

export const colorsList = [
  "Beige",
  "Black",
  "Blue",
  "Brown",
  "Burgundy",
  "Ecru",
  "Gold",
  "Green",
  "Grey",
  "Khaki",
  "Multicolor",
  "Navy",
  "Orange",
  "Pink",
  "Purple",
  "Red",
  "Silver",
  "White",
  "Yellow",
];

export const compositionList = [
  "Acetate",
  "Acrylic",
  "Cotton",
  "Elasthan",
  "Linen",
  "Linen blends",
  "Lyocell",
  "Monomaterial",
  "Natural",
  "Organic fabrics",
  "Polyamide",
  "Polyester",
  "Polyester blends",
  "Recycled fabrics",
  "Synethetic",
  "Tencel",
  "Viscose",
  "Viscose blends",
  "Wool",
  "Wool blends",
];

export const fiberTypes = [
  "Animal",
  "Natural",
  "Organic",
  "Recycled",
  "Synethetic",
  "Vegetable",
];

export const usageList = [
  "Blouse",
  "Casual",
  "Denim",
  "Dress",
  "Formal",
  "Jackets",
  "Outerwear",
  "Shirts",
  "Suits",
  "Sports",
  "Trousers",
];

export const designsList = [
  "No Design",
  "Checkered",
  "Flowered",
  "Plain",
  "Prints",
  "Striped",
  "Structured",
];

export const categoriesList = [
  "Yarn dyed",
  "Denim",
  "Embroidery",
  "Imitation skins and furs",
  "Knits",
  "Lace",
  "Plains",
  "Prints",
];

export const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
];

type WeightFilters = {
  value: string[];
  label: string;
};

export const weightFilters: WeightFilters[] = [
  {
    value: ["0", "80"],
    label: "Extra Light < 80 g/m2",
  },
  {
    value: ["80", "135"],
    label: "Light 80-135 g/m2",
  },
  {
    value: ["135", "275"],
    label: "Medium 135-270 g/m2",
  },
  {
    value: ["270", "400"],
    label: "Heavy 270-400 g/m2",
  },
  {
    value: ["270", "99999"],
    label: "Extra Heavy >400 g/m2",
  },
];

type WidthFilters = {
  value: string[];
  label: string;
};

export const widthFilters: WidthFilters[] = [
  {
    value: ["0", "120"],
    label: "<120cm",
  },
  {
    value: ["119", "151"],
    label: "120-150 cm",
  },
  {
    value: ["150", "999999"],
    label: ">150 cm",
  },
];

type PriceFilters = {
  value: string[];
  label: string;
};

export const PriceFilters: PriceFilters[] = [
  {
    value: ["0", "250"],
    label: "0-250",
  },
  {
    value: ["250", "500"],
    label: "250-500",
  },
  {
    value: ["500", "750"],
    label: "500-750",
  },
  {
    value: ["750", "1000"],
    label: "750-1000",
  },
];

export const patternList = ["No Pattern", "Striped", "Checkered", "Dots"];

export const countries = countryList().getData();
