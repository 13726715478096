import { Lock } from "@mui/icons-material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LoadingButton } from "@mui/lab";
import { FormControl, InputAdornment } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import Typography from "@mui/material/Typography";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axiosClient from "../api/axiosClient";
import { resetPasswordSchema } from "../components/schemas";

export default function ResetPassword() {
  const params = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/random?fabrics)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Formik
            validationSchema={resetPasswordSchema}
            initialValues={{
              newPassword: "",
              confirmPassword: "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              const data = {
                token: params.token,
                newPassword: values.newPassword,
                confirmPassword: values.confirmPassword,
              };
              axiosClient
                .post(`/api/auth/change_password`, data)
                .then((res) => {
                  enqueueSnackbar(t("Successfully reset password.1"), {
                    variant: "success",
                    preventDuplicate: true,
                  });
                  // getAccountInfo();
                })

                .catch((err) => {
                  enqueueSnackbar(t(JSON.parse(err.request.response).message), {
                    variant: "error",
                    preventDuplicate: true,
                  });
                });
            }}>
            {({ values, errors, isSubmitting, isValidating, submitForm }) => (
              <Form>
                <FormControl fullWidth>
                  <Field
                    component={TextField}
                    label={t("New Password.1")}
                    name="newPassword"
                    type="password"
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Field
                    component={TextField}
                    label={t("Confirm Password.1")}
                    name="confirmPassword"
                    type="password"
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  size="large"
                  loading={isSubmitting || isValidating}
                  sx={{
                    marginTop: "1rem",
                    backgroundColor: "secondary.main",
                    color: "#fff",
                  }}
                  type="submit">
                  {t("Save changes.1")}
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
}
