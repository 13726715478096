import * as yup from "yup";

const passwordRules =
  /^((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

export const registerSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("First name is required"),
  lastName: yup
    .string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Last name is required"),
  email: yup.string().email("Please enter a valid email").required("Required"),

  password: yup
    .string()
    .min(8)
    .matches(
      passwordRules,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .required("Password is required"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Password has to be confirmed"),
});

export const loginSchema = yup.object().shape({
  email: yup.string().email("Please enter a valid email").required("Required"),
  password: yup.string().required("Password is required"),
});

export const updatePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required("Fill in your current password"),
  newPassword: yup.string().required("Fill in your new password"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
});
export const resetPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8)
    .matches(
      passwordRules,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .required("Fill in your new password"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
});

export const ProductFormSchema = yup.object().shape({
  title: yup.string().required("Title is mandatory"),
  description: yup.string().required("Description is mandatory").max(1000),
  category: yup.string().required("Category is mandatory"),
  colors: yup.array(yup.string()).min(1).required("Colors are mandatory"),
  materials: yup.array(yup.string()).min(1).required("Materials are mandatory"),
  length: yup.number().required(),
  weight: yup.number().required(),
  price: yup.number().required(),
  width: yup.number().required(),
  currency: yup.string().required(),
  compositions: yup.array(yup.string()).min(1).required(),
  countryCode: yup.string().required(),
  fibers: yup.array(yup.string()).min(1).required(),
  usages: yup.array(yup.string()).min(1).required(),
  designs: yup.array(yup.string()).required(),
});

export const CreateOrganizationFormSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required().max(1000),
  email: yup.string().email("Please enter a valid email").required(),
  // countryCode: yup.string().required(),
  terms: yup.bool().oneOf([true], "You have to agree to our terms of service"),
});

export const MembershipFormSchema = yup.object().shape({
  firstName: yup.string().required().max(255),
  lastName: yup.string().required().max(255),
  streetName: yup.string().required().max(255),
  streetNumber: yup.string().required().max(255),
  city: yup.string().required().max(255),
  state: yup.string().required().max(255),
  postalCode: yup.string().required().max(255),
  country: yup.string().required().max(255),
});

export const EditOrganizationFormSchema = yup.object().shape({
  name: yup.string().required().max(255),
  description: yup.string().required().max(1000),
  email: yup.string().required().max(255),
  phone: yup.string().required(),
  countryCode: yup.string().required(),
});

export const CreateWishlistFormSchema = yup.object().shape({
  name: yup.string().required().max(255),
});

export const EditUserFormSchema = yup.object().shape({
  firstName: yup.string().required().max(255),
  lastName: yup.string().required().max(255),
  phoneNumber: yup.string().required(),
});
