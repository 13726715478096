import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { lazy, Suspense, useState } from "react";
import { useTranslation } from "react-i18next";

const NewestGallery = lazy(() => import("./Gallery/NewestGallery"));
const PopularGallery = lazy(() => import("./Gallery/PopularGallery"));

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ p: 3, display: "flex" }}
          alignItems="center"
          justifyContent="center"
        >
          {children}
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          // TabIndicatorProps={{ sx: { display: "none" } }}
          centered
          sx={{ justifyContent: "space-around " }}
        >
          <Tab
            sx={{ fontSize: "22px" }}
            label={t("New Fabrics.1")}
            {...a11yProps(0)}
          />
          <Tab
            sx={{ fontSize: "22px" }}
            label={t("Popular.1")}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      {/* Replace with indivual components */}
      <TabPanel value={value} index={0}>
        <Suspense fallback={<div></div>}>
          <NewestGallery />
        </Suspense>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Suspense fallback={<div></div>}>
          <PopularGallery />
        </Suspense>
      </TabPanel>
    </Box>
  );
}
