import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useTranslation } from "react-i18next";
import axiosClient from "../../api/axiosClient";

export default function ResendConfirmation() {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [email, setEmail] = React.useState("example@mail.com");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  // password changing method
  const onSubmit = () => {
    return axiosClient
      .get(`/api/auth/resend_verification?email=${email}`)
      .then((res) => {
        enqueueSnackbar("Succesfully sent verification email", {
          variant: "success",
          preventDuplicate: true,
        });

        setOpen(false);
      })

      .catch((err) => {
        enqueueSnackbar("Failed to send verification email", {
          variant: "error",
          preventDuplicate: true,
        });
      });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        sx={{ textTransform: "none" }}
        variant="contained"
        color="primary"
        size="small"
        onClick={handleClickOpen}
      >
        {t("Resend confirmation.1")}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("Request token.1")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(
              "To gain access to your account, you need to request a confirmation token.1"
            )}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel.1")}</Button>
          <Button onClick={onSubmit}>{t("Submit.1")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
