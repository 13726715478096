import { Box } from "@mui/material";
import { Widget } from "@typeform/embed-react";

const TypeFormSection = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: { xs: "100vh", md: "50vh" },
        borderTop: "1px solid #ff8945",
      }}>
      <Widget id="WhZIr3mz" style={{ height: "100%" }} />
    </Box>
  );
};

export default TypeFormSection;
