import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";

import i18n from "../../i18next";
// TODO: Update with more languages
export default function LanguageSelect() {
  const [language, setLanguage] = useState<string | undefined>("en");

  const handleChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value as string);
    i18n.changeLanguage(event.target.value as string);
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);

  return (
    <Box sx={{ maxWidth: 80 }}>
      <FormControl fullWidth size="small">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language}
          defaultValue="NL"
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            ".MuiSelect-icon": {
              color: "#000",
            },  
            color: "#000",
          }}
          onChange={handleChange}
        >
          <MenuItem value="en">EN</MenuItem>

          <MenuItem disabled value="nl">
            NL
          </MenuItem>
          <MenuItem disabled value="de">
            DE
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
