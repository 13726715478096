import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";

const CloseSnackbarAction = ({ id }: any) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      style={{ marginTop: "0.1em" }}
      onClick={() => {
        closeSnackbar(id);
      }}
    >
      <Close />
    </IconButton>
  );
};

export default CloseSnackbarAction;
