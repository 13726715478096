import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as React from "react";

export default function SearchSelect() {
  const [searchType, setSearchType] = React.useState("Product");

  const handleChange = (event: SelectChangeEvent) => {
    setSearchType(event.target.value as string);
  };

  //   TODO: Add list of options
  //   TODO: Update Context

  // TODO: Add props/types
  // TODO: Add passable params
  return (
    <Box sx={{ width: 120 }}>
      <FormControl fullWidth size="small">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={searchType}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            ".MuiSelect-icon": {
              color: "#4D4B4B",
            },
            color: "#4D4B4B",
            fontWeight: 600,
            background: "#fff",

            borderRadius: "4px 0px 0px 4px",
          }}
          onChange={handleChange}
        >
          <MenuItem value="Product">Product</MenuItem>
          <MenuItem value="Material">Material</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
