import { Lock } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { FormControl, InputAdornment } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useTranslation } from "react-i18next";
import axiosClient from "../../api/axiosClient";
import { updatePasswordSchema } from "../schemas";
// TODO: Translation
export default function UpdatePasswordDialog() {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        sx={{ textTransform: "none" }}
        variant="contained"
        color="secondary"
        onClick={handleClickOpen}
      >
        {t("Update Password.1")}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("Update Password.1")}</DialogTitle>
        <DialogContent>
          <Formik
            validationSchema={updatePasswordSchema}
            initialValues={{
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              const data = {
                oldPassword: values.oldPassword,
                newPassword: values.newPassword,
                confirmPassword: values.confirmPassword,
              };

              axiosClient
                .post(`/api/auth/update_password`, data)
                .then((res) => {
                  enqueueSnackbar(t("Successfully changed password.1"), {
                    variant: "success",
                    preventDuplicate: true,
                  });
                  // getAccountInfo();
                })

                .catch((err) => {
                  enqueueSnackbar(t("Failed to change password.1"), {
                    variant: "error",
                    preventDuplicate: true,
                  });
                });
            }}
          >
            {({ values, errors, isSubmitting, isValidating, submitForm }) => (
              <Form>
                <FormControl fullWidth>
                  <Field
                    component={TextField}
                    name="oldPassword"
                    type="password"
                    label={t("Current Password.1")}
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Field
                    component={TextField}
                    label={t("New Password.1")}
                    name="newPassword"
                    type="password"
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Field
                    component={TextField}
                    label={t("Confirm Password.1")}
                    name="confirmPassword"
                    type="password"
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  size="large"
                  loading={isSubmitting || isValidating}
                  sx={{
                    marginTop: "1rem",
                    backgroundColor: "secondary.main",
                    color: "#fff",
                  }}
                  type="submit"
                >
                  {t("Save changes.1")}
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
