import { ExpandMore } from "@mui/icons-material";

import { InputLabel, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as React from "react";
import { useNavigate } from "react-router-dom";

const categories = [
  { name: "Colour woven" },
  { name: "Denim" },
  { name: "Embroidery" },
  { name: "Imitation skins and furs" },
  { name: "Knits" },
  { name: "Lace" },
  { name: "Plains" },
  { name: "Prints" },
];

export default function CategoriesSelect() {
  const navigate = useNavigate();
  const [searchType, setSearchType] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setSearchType(event.target.value as string);
    navigate(("/view-category-page/" + event.target.value) as string);
  };

  return (
    <FormControl fullWidth size="small" sx={{ maxWidth: { xs: 600, md: 220 } }}>
      <InputLabel id="demo-simple-select-label">
        <Typography variant="subtitle2" color="#000">
          All Categories
        </Typography>
      </InputLabel>
      <Select
        IconComponent={ExpandMore}
        id="demo-simple-select"
        value={searchType}
        sx={{
          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          ".MuiSelect-icon": {
            color: "#000",
          },
          color: "#627a70",
          background: "#fff",
        }}
        onChange={handleChange}
      >
        {categories.map((item, i) => {
          return (
            <MenuItem key={i} value={item.name}>
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
