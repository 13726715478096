import Button from "@mui/material/Button";

export default function LinkButton({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) {
  return (
    <Button sx={{ color: "#fff" }} variant="contained" href={href}>
      {children}
    </Button>
  );
}

export function LinkButtonBlack({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) {
  return (
    <Button
      sx={{
        color: "#000",
        borderColor: "#000",
        "&:hover": {
          borderColor: "#000",
        },
      }}
      variant="outlined"
      href={href}>
      {children}
    </Button>
  );
}
