import { Box, Grid, Stack } from "@mui/material";
import Image from "mui-image";
import { useEffect, useState } from "react";

const ProductImageCarousel = ({ images }: FCVariantCarouselProps) => {
  const [selectedVariant, setSelectedVariant] = useState(0);
  const [imagesList, setImagesList] = useState([{}]);

  useEffect(() => {
    setImagesList(images);
  });
  return (
    <Box sx={{ flexGrow: 1, width: "80%" }}>
      <Grid container spacing={5}>
        <Grid item container xs={12} md={3} spacing={2}>
          <Stack
            spacing={3}
            sx={{
              width: "100%",
              height: "100%",
            }}
            direction={{ xs: "row", md: "column" }}
            alignItems="center"
            justifyContent={{ xs: "center", md: "" }}
          >
            {imagesList.slice(0, 3).map((item: any, i: any) => {
              return (
                <Box
                  key={i}
                  onClick={() => setSelectedVariant(i)}
                  sx={{
                    width: { xs: "20vw", md: 150 },
                    height: { xs: "20vh", md: 150 },
                  }}
                >
                  <Image src={`${item}`} fit="contain" />
                </Box>
              );
            })}
          </Stack>
        </Grid>
        <Grid item xs={12} md={9}>
          <Image
            src={`${imagesList[selectedVariant]}`}
            height="60vh"
            fit="contain"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductImageCarousel;
