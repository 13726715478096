import { Box, Stack, Typography } from "@mui/material";
import LinkButton from "../LinkButton";

const BuyerSupplierSection = ({ title }: { title: string }) => {
  return (
    <Box
      id="buyer-supplier"
      sx={{ width: "100vw", marginY: "20vh", display: "flex" }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Stack
        spacing={8}
        sx={{ width: "80%", textAlign: "center" }}
      >
        <Typography
          variant="h2"
          sx={{ fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <Stack
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          direction="row"
          spacing={4}
        >
          <LinkButton href="/buyers-guide">I am a buyer</LinkButton>
          <LinkButton href="/sellers-guide">I am a supplier</LinkButton>
        </Stack>
      </Stack>
    </Box>
  );
};

export default BuyerSupplierSection;
