import { Box, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import image from "../../assets/images/rolls-fabric.webp";

const ConceptInfoSection = ({
  title,
  highlight1,
  subtitle1,
  highlight2,
  subtitle2,
  highlight3,
  subtitle3,
}: {
  title: string;
  highlight1: string;
  subtitle1: string;
  highlight2: string;
  subtitle2: string;
  highlight3: string;
  subtitle3: string;
}) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    let buyerSupplier = document.getElementById("buyer-supplier");
    buyerSupplier &&
      buyerSupplier.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <Box
      id="concept-info"
      sx={{ width: "100vw", display: "flex" }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Stack
        direction={{ xs: "column", md: "row" }}
        sx={{
          paddingLeft: "10%",
          paddingRight: { xs: "10%", md: "0" },
          width: "100%",
        }}
      >
        <Stack
          spacing={8}
          sx={{
            marginY: "10vh",
            paddingX: { xs: "10vw", md: "0" },
            width: "100%",
            height: "100%",
          }}
        >
          <Typography
            variant="h2"
            sx={{ fontWeight: "bold", width: "80%" }}
          >
            {title}
          </Typography>
          <Stack spacing={4}>
            <Typography
              variant="h4"
              component="h3"
            >
              <Box
                component="span"
                sx={{ color: "primary.main" }}
              >
                {`${highlight1} `}
              </Box>
              {subtitle1}
            </Typography>
            <Typography
              variant="h4"
              component="h3"
            >
              <Box
                component="span"
                sx={{ color: "primary.main" }}
              >
                {`${highlight2} `}
              </Box>
              {subtitle2}
            </Typography>
            <Typography
              variant="h4"
              component="h3"
            >
              <Box
                component="span"
                sx={{ color: "primary.main" }}
              >
                {`${highlight3} `}
              </Box>
              {subtitle3}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
          >
            <Button
              variant="contained"
              sx={{ color: "white" }}
              onClick={(e) => handleClick(e)}
            >
              How does it work?
            </Button>
          </Stack>
        </Stack>
        <Box
          component="img"
          sx={{
            width: { xs: "120vw", md: "33vw", xl: "50vw" },
            objectFit: "cover",
            marginX: { xs: "-10vw", md: "0" },
          }}
          alt="Photo of rolls of fabric"
          src={image}
        ></Box>
      </Stack>
    </Box>
  );
};

export default ConceptInfoSection;
