import {
  ArrowBack,
  Favorite,
  FavoriteBorderOutlined,
  Share,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import axiosClient from "../api/axiosClient";
import { getSingleListing } from "../api/listingsApi";
import RelatedListingCard from "../components/Cards/RelatedListingCard";
import ProductImageCarousel from "../components/Carousel/ProductImageCarousel";
import ShareDialog from "../components/Dialog/ShareDialog";
import NoProducts from "../components/Error/NoProducts";
import Loading from "../components/Loading";
import ProductTabs from "../components/ProductTabs";
import { DownloadHeader, HeaderText } from "../components/Text/TextVariants";
import { useAppContext } from "../contexts/AppContext";
import { useAuth } from "../contexts/AuthContext";
//  TODO: Remove reliance on currentProduct
const Listing = () => {
  const params = useParams();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [loading, setLoading] = useState(true);
  const [liked, setLiked] = useState(false);
  const [likedListings, setLikedListings] = useState([]);
  const [relatedListings, setRelatedListings] = useState<FCProduct[]>([]);
  const navigate = useNavigate();

  const { auth } = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const [copyValue, setCopyValue] = useState("");

  const {
    isLoading,
    status,
    error,
    data: singleListing,
    isFetching,
    isPreviousData,
  } = useQuery(
    ["singleListing", params?.productid],
    () =>
      getSingleListing({
        organisation: params?.vendor,
        listingId: params?.productid,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!params.vendor,
    }
  );

  const contactSeller = (listing: any) => {
    navigate(`/contact-seller/${listing.vendor}/${listing.id}?type=contact`);
  };

  const requestSample = (listing: any) => {
    navigate(`/contact-seller/${listing.vendor}/${listing.id}?type=sample`);
  };

  function getFavoritedListings() {
    return axiosClient
      .get(`api/user/current/likes`)
      .then((res: any) => {
        setLikedListings(res.data.content.map((obj: any) => obj.id));

        let likedIDs = res.data.content.map((obj: any) => obj.id);

        // Check if product has been liked before
        if (likedIDs.includes(params.productid)) {
          setLiked(true);
        }
      })
      .catch((err) => {});
  }

  function getRelatedListings() {
    getSingleListing({
      organisation: params?.vendor,
      listingId: params?.productid,
    }).then((res: any) => {
      return axiosClient
        .get(
          `/api/search/listing?search=${res.options.materials}"&colours=${res.options.colors}`
        )
        .then((res: any) => {
          console.log(res.data.content);
          setRelatedListings(res.data.content);
        })
        .catch((err) => {});
    });
  }

  function likeItem() {
    if (!!auth) {
      return axiosClient
        .post(
          `api/organisation/${params.vendor}/listing/${params.productid}/like`
        )
        .then((res: any) => {
          setLiked(true);
        })
        .catch((err: any) => {
          enqueueSnackbar("Unable to like this product", {
            variant: "error",
            preventDuplicate: true,
          });
        });
    } else {
      enqueueSnackbar("You have to be logged in to like this product", {
        variant: "info",
        preventDuplicate: true,
      });
    }
  }

  function dislikeItem() {
    return axiosClient
      .post(
        `api/organisation/${params.vendor}/listing/${params.productid}/unlike`
      )
      .then((res: any) => {
        setLiked(false);
      })
      .catch((err: any) => {
        enqueueSnackbar("Unable to unlike this product", {
          variant: "error",
          preventDuplicate: true,
        });
      });
  }

  useEffect(() => {
    getFavoritedListings();
    const nameUrl = window.location.href;

    setCopyValue(nameUrl);

    getRelatedListings();
  }, [params.productid]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <DownloadHeader />

      {isLoading || isFetching ? (
        <Box sx={{ width: "100%", minHeight: "100vh", pt: 2 }}>
          <Loading />
        </Box>
      ) : status === "error" ? (
        <Box sx={{ width: "100%", minHeight: "100vh", pt: 2 }}>
          {/* TODO:Translation/ code */}
          Unable to fetch this listing. Please try again later.
        </Box>
      ) : !!singleListing ? (
        <Grid
          container
          spacing={2}
          sx={{ width: "100%", height: "100%", pt: 2 }}>
          <Grid item xs={12}>
            <Button
              size="large"
              sx={{ ml: 6 }}
              startIcon={<ArrowBack />}
              onClick={() => navigate(-1)}>
              Back
            </Button>
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack
              sx={{ width: "100%", height: "100%", pt: { md: 4 } }}
              direction="column"
              alignItems="center">
              <ProductImageCarousel images={singleListing!.imagesURL} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack
              sx={{
                width: "100%",
                height: "100$",
                alignItems: { xs: "center", md: "flex-start" },
              }}
              pt={4}
              spacing={12}
              direction="column"
              // alignItems="flex-start"
            >
              <HeaderText
                text={!!singleListing ? singleListing!.title : "default"}
              />
              <Stack
                direction="column"
                justifyContent="space-between"
                sx={{ width: "100%", height: "100%" }}
                spacing={3}>
                <Stack>
                  <Typography
                    sx={{
                      textAlign: { xs: "center", md: "inherit" },
                      fontWeight: "bold",
                    }}
                    variant="body1">
                    {!isLoading
                      ? `€${singleListing.options.price.toFixed(2)} /m`
                      : "0"}
                  </Typography>
                </Stack>

                <Stack>
                  <Typography
                    sx={{ textAlign: { xs: "center", md: "inherit" } }}>
                    Available length
                  </Typography>

                  <Typography
                    sx={{ textAlign: { xs: "center", md: "inherit" } }}>
                    {!isLoading
                      ? `${singleListing.options.length.toString()} m`
                      : "0"}
                  </Typography>
                </Stack>

                <Stack
                  sx={{ alignItems: { xs: "center", md: "flex-start" } }}
                  spacing={2}>
                  <Typography>Colours</Typography>
                  {/* TODO: grid of chips */}
                  <Grid
                    container
                    sx={{
                      alignItems: { xs: "center", md: "flex-start" },
                      justifyContent: { xs: "center", md: "flex-start" },
                    }}
                    gap={2}>
                    {singleListing.options.colors.map((color: any) => {
                      return (
                        <Grid item key={color}>
                          <Chip
                            label={
                              <Typography variant="subtitle2">
                                {color ?? "None"}
                              </Typography>
                            }
                            sx={{ width: 120 }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Stack>

                <Stack>
                  {!!singleListing ? <ProductTabs {...singleListing} /> : null}
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    justifyContent: { xs: "space-around", md: "space-between" },
                  }}>
                  <Tooltip title={!!auth ? "" : "You need to be logged in"}>
                    <Stack direction={"row"} gap={2}>
                      <Button
                        disabled={!!auth === false}
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={() => contactSeller(singleListing)}>
                        Contact Seller
                      </Button>
                      <Button
                        disabled={!!auth === false}
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={() => requestSample(singleListing)}>
                        Request a sample
                      </Button>
                    </Stack>
                  </Tooltip>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center">
                    {/* {!!singleListing && (
                      <WishlistPopover listingId={singleListing!.id} />
                    )} */}

                    {liked ? (
                      <IconButton
                        onClick={() => {
                          dislikeItem();
                        }}
                        aria-label="add to favorites">
                        <Favorite color="error" sx={{ fontSize: 40 }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          likeItem();
                        }}
                        aria-label="add to favorites">
                        <FavoriteBorderOutlined
                          sx={{ fontSize: 40, color: "#4d4b4b" }}
                        />
                      </IconButton>
                    )}
                    <IconButton onClick={handleClickOpen}>
                      <Share sx={{ fontSize: 40, color: "#4d4b4b" }} />
                    </IconButton>
                    <ShareDialog
                      open={open}
                      onClose={handleClose}
                      shareURL={copyValue}
                      title={singleListing.title ?? "Default Product"}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ mb: 4 }}>
            <Stack sx={{}} pt={4} spacing={12} direction="column">
              <HeaderText text="Related listing" />
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
                sx={{ width: "100%" }}>
                {relatedListings.length !== 0 ? (
                  relatedListings.slice(0, 5).map((item, i) => {
                    return (
                      <Grid
                        item
                        alignItems="center"
                        justifyContent="center"
                        xs={12}
                        sm={6}
                        md={4}
                        lg={2.4}
                        key={i}>
                        <RelatedListingCard {...item} />
                      </Grid>
                    );
                  })
                ) : (
                  <NoProducts />
                )}
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <div>Unknown error has occured</div>
      )}
    </Box>
  );
};

export default Listing;
