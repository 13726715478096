import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import AdvancedDropzoneDemo from "../Dropzone/BasicDropzone";

const UploadDialog = ({
  openUpload,
  setB64Images,
  setOpenUpload,
}: {
  openUpload: boolean;
  setB64Images: (b64Images: any) => void;
  setOpenUpload: (open: boolean) => void;
}) => {
  return (
    <>
      <Dialog open={openUpload}>
        <DialogTitle>Upload images</DialogTitle>
        <DialogContent>
          <AdvancedDropzoneDemo
            setb64Images={setB64Images}
            setOpenUpload={setOpenUpload}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUpload(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadDialog;
