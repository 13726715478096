import { createContext, useContext, useState } from "react";

export const AppContext = createContext();

export function useAppContext() {
  return useContext(AppContext);
}

function AppContextProvider({ children }) {
  const [currentOrg, setCurrentOrg] = useState({});
  const [currentProduct, setCurrentProduct] = useState({});
  const [currentSeller, setCurrentSeller] = useState({});
  const [likedProduct, setLikedProduct] = useState([]);
  const value = {
    currentOrg,
    setCurrentOrg,
    currentProduct,
    setCurrentProduct,
    currentSeller,
    setCurrentSeller,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export default AppContextProvider;
