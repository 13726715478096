import axiosClient from "./axiosClient";

export const updateUserInfo = async (data: any) => {
  const response = await axiosClient.put(`/api/user/current/update`, data);

  return response.data;
};

export const updateOrgInfo = async (request : {data: any, organisation:any}) => {
  const response = await axiosClient.put(`/api/organisation/update/${request.organisation}`, request.data);

  return response.data;
}

export const getSellerInfo = async (organisation: any) => {
  const response = await axiosClient.get(`/api/organisation/${organisation}`);

  return response.data;
}

export const uploadProfilePicture = async (image: {fileContent: any , fileName: string}) => {
  const response = await axiosClient
  .put(`api/user/current/update`, {
    image: { fileContentBase64: image.fileContent, fileName: image.fileName },
  });

  return response.data;
}

export const getUserInfo = async () => {
  const response = await axiosClient.get(`/api/user/current`);

  return response.data
}