import { CardActionArea, CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

export default function RelatedListingCard(product: FCProduct) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const onSelected = (product: FCProduct) => {
    navigate("/product-page/" + product.vendor + "/" + product.id);
    queryClient.refetchQueries("singleListing");
  };

  return (
    <Card elevation={0} sx={{ maxWidth: { lg: 400 } }}>
      <CardActionArea onClick={() => onSelected(product)}>
        <CardMedia
          component="img"
          width="200"
          height="200"
          image={`${product.imagesURL[0]}`}
          sx={{ objectFit: "cover", imageRendering: "auto" }}
        />
      </CardActionArea>

      <CardHeader
        disableTypography
        title={
          <Typography variant="subtitle2" textAlign="center">
            {product.title}
          </Typography>
        }
      />
    </Card>
  );
}
