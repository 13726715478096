import { Box, Stack, Typography } from "@mui/material";
import LinkButton from "../LinkButton";
import Button from "@mui/material/Button";
import background from "../../assets/images/background.webp";
import { Widget } from "@typeform/embed-react";

const HeroSection = ({
  title1,
  title2,
  subtitle,
}: {
  title1: string;
  title2: string;
  subtitle: string;
}) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    let conceptInfo = document.getElementById("concept-info");
    conceptInfo &&
      conceptInfo.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "calc(100vh - 40px)",
        display: "flex",
        position: "relative",
      }}
      justifyContent={"center"}
      alignItems={"center"}>
      <Box
        component="img"
        sx={{
          position: "absolute",
          width: "100vw",
          height: "calc(100vh - 40px)",
          zIndex: 0,
        }}
        alt="Background of waving fabric"
        src={background}
      />
      <Stack
        spacing={8}
        sx={{
          width: { xs: "80%", xl: "60%" },
          textAlign: "center",
          zIndex: 1,
          paddingY: "10vh",
        }}>
        <Typography
          variant="h1"
          sx={{
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: { xs: "3rem", md: "4rem", lg: "5rem" },
          }}>
          {title1}
          <br></br>
          {title2}
        </Typography>
        <Typography
          variant="h4"
          component="h2"
          sx={{ fontSize: { xs: "1rem", md: "2rem", lg: "2.5rem" } }}>
          {subtitle}
        </Typography>
        <Stack
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          direction="row"
          spacing={4}>
          <LinkButton href="/search-page">Find your fabrics</LinkButton>
          <Button
            sx={{ color: "white" }}
            variant="contained"
            onClick={(e) => handleClick(e)}>
            Learn more
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default HeroSection;
