import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import LoginForm from "../Forms/LoginForm";

const RequireAuth = ({ allowedRoles }) => {
  const [open, setOpen] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false); // holds admin status
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  const { auth } = useAuth();
  const location = useLocation();
  //   TODO: Login/Register dialog
  //   TODO: Add role based auth v2
  useEffect(() => {
    // (auth);

    // checks for admin authority
    auth?.authorities.forEach((authority) => {
      console.log(authority.authority);
      if (
        authority.authority === "ROLE_ADMIN" ||
        authority.authority === "ADMIN"
      ) {
        setIsAdmin(true); // sets admin to true
      }
    });
  }, [auth]);

  return auth?.name ? (
    <>
      {allowedRoles.includes("admin") && !isAdmin ? (
        // shows login dialog if not admin
        <Box>
          <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title" sx={{ textAlign: " center" }}>
              {"Login as admin account to access"}
            </DialogTitle>
            <DialogContent>
              <LoginForm />
            </DialogContent>
          </Dialog>
        </Box>
      ) : (
        <Outlet /> // shows page if logged in as admin
      )}
    </>
  ) : (
    // shows login dialog if not logged in at all
    <Box>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: " center" }}>
          {"Login to Access"}
        </DialogTitle>
        <DialogContent>
          <LoginForm />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default RequireAuth;
