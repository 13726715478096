import { ChevronRight } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";

function generate(element: React.ReactElement) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

export default function ContactedSellerList({ contacts }: FCContactProps) {
  return (
    // TODO: Change Color
    <Box sx={{ flexGrow: 1, maxWidth: 1000, height: "100%" }}>
      <Grid container>
        <Grid item xs={12}>
          <List>
            {contacts.map((contact) => {
              return (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="visit">
                      <ChevronRight fontSize="large" />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        border: "solid",
                        borderColor: "primary.main",
                      }}
                    >
                      N
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: 16,
                      fontWeight: 500,
                      color: "primary.main",
                    }}
                    primary={contact.name}
                    secondary={contact.company}
                  />
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
}
