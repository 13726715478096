import { Favorite, FavoriteBorderOutlined } from "@mui/icons-material";
import { CardActionArea, CardHeader, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { useNavigate } from "react-router-dom";
import axiosClient from "../../api/axiosClient";
import { likeListing, unlikeListing } from "../../api/listingsApi";
import { useAppContext } from "../../contexts/AppContext";
import { useAuth } from "../../contexts/AuthContext";

// TODO: Add mutation for instant change

interface ProductCardProps {
  listing: FCProduct;
  hasBeenLiked?: boolean;
}

function ListingCard({ listing, hasBeenLiked = false }: ProductCardProps) {
  const [liked, setLiked] = useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { setCurrentProduct } = useAppContext();
  const queryClient = useQueryClient();
  const { auth } = useAuth();
  const onSelected = (listing: FCProduct) => {
    setCurrentProduct(listing);

    navigate("/product-page/" + listing.vendor + "/" + listing.id);
  };

  function likeItem() {
    likeListingMutation.mutate({ vendor: listing.vendor, id: listing.id });
  }

  function dislikeItem() {
    unlikeListingMutation.mutate({ vendor: listing.vendor, id: listing.id });
  }

  const likeListingMutation = useMutation(likeListing, {
    onSuccess: () => {
      // Invalidates cache and refetch
      setLiked(true);
      queryClient.invalidateQueries("myFavoritedProducts");
    },
    onError: (err) => {
      enqueueSnackbar("Unable to like listing", {
        variant: "error",
        preventDuplicate: true,
      });
    },
  });

  const unlikeListingMutation = useMutation(unlikeListing, {
    onSuccess: () => {
      // Invalidates cache and refetch
      setLiked(false);
      queryClient.invalidateQueries("myFavoritedProducts");
    },
    onError: (err) => {
      enqueueSnackbar("Unable to unlike listing", {
        variant: "error",
        preventDuplicate: true,
      });
    },
  });

  React.useEffect(() => {
    setLiked(hasBeenLiked);
  }, [listing, hasBeenLiked]);
  return (
    <Card elevation={0} sx={{ maxWidth: 400 }}>
      <CardActionArea onClick={() => onSelected(listing)}>
        <CardMedia
          component="img"
          width="200"
          height="200"
          image={`${listing.imagesURL[0]}`}
          alt="product image"
          // sx={{ objectFit: "contain" }}
        />
      </CardActionArea>

      {auth !== null ? (
        <CardHeader
          disableTypography
          action={
            liked ? (
              <IconButton
                onClick={() => {
                  dislikeItem();
                }}
                aria-label="add to favorites">
                <Favorite color="error" />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  likeItem();
                }}
                aria-label="add to favorites">
                <FavoriteBorderOutlined color="primary" />
              </IconButton>
            )
          }
          title={<Typography variant="subtitle2">{listing.title}</Typography>}
        />
      ) : (
        <>
          <CardHeader
            disableTypography
            title={
              <Typography variant="subtitle2" textAlign="center">
                {listing.title}
              </Typography>
            }
          />
          {listing.options.price && (
            <Typography
              sx={{ mt: "-1em", fontSize: "0.9em" }}
              variant="body1"
              textAlign="center">
              &euro;{`${listing.options.price.toFixed(2)} /m`}
            </Typography>
          )}
        </>
      )}
    </Card>
  );
}

export default React.memo(ListingCard);
