import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import HeroSection from "../components/Sections/HeroSection";
import ConceptInfoSection from "../components/Sections/ConceptInfoSection";
import BuyerSupplierSection from "../components/Sections/BuyerSupplierSection";
import ImageCarousel from "../components/Carousel/ImageCarousel";
import ReportCompleteSnackbar from "../components/Notifications/FabricRequest";
import { useSnackbar } from "notistack";

import { CategoryStack } from "../components/Categories";
import BasicTabs from "../components/GalleryTabs";
import { useAuth } from "../contexts/AuthContext";
import TypeFormSection from "../components/Sections/TypeFormSection";
// TODO: Move to MainFeaturedPost conditions for less rendering
const Homepage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoggedin, setIsLoggedin] = useState(false);
  const { auth } = useAuth();
  useEffect(() => {
    auth?.name ? setIsLoggedin(true) : setIsLoggedin(false);
  }, [auth, enqueueSnackbar]);
  return (
    <Box sx={{ width: "100%", height: "100%", minHeight: "100vh" }}>
      <HeroSection
        title1={"Reclaimed fabrics"}
        title2={"from top suppliers"}
        subtitle={
          "We believe that every inch of fabric is valuable and should not be wasted! Our online marketplace allows you to easily find, buy and sell high quality deadstock fabrics."
        }
      />

      <ConceptInfoSection
        title="We promote the use of existing materials"
        highlight1="Reclaim"
        subtitle1="high quality fabrics"
        highlight2="Refuse"
        subtitle2="new production"
        highlight3="Reduce"
        subtitle3="waste"
      />

      <BuyerSupplierSection title="Ready to join the fabric (ex)change?" />

      <TypeFormSection />

      {/* TODO: Add reviews sections */}
      {/* <ImageCarousel /> */}

      <CategoryStack />

      <Box px={2}>
        <BasicTabs />
      </Box>
    </Box>
  );
};

export default Homepage;
