import { Email, Flag, VerifiedUser } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function SellerInfoCard(seller: FCSeller) {
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  useEffect(() => {}, []);

  return (
    <Card elevation={0}>
      <CardHeader
        avatar={
          <Avatar
            sx={{ border: "solid", borderColor: "primary.main" }}
            aria-label="avatar"
            src={`${seller.imageURL}`}
            imgProps={{ sx: { objectFit: "scale-down" } }}
          />
        }
        action={
          <IconButton
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        }
        title={seller.name}
      />

      <CardContent>
        <Typography variant="subtitle2" color="primary.main">
          {seller.description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Typography>Extra info</Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <Email />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Email" secondary={seller.email} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <Flag />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Country" secondary={seller.countryCode} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <VerifiedUser />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Joined at"
              secondary={dayjs(`${seller.createdAt}`).format("LL")}
            />
          </ListItem>
        </List>
      </Collapse>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={() => navigate("/view-shop/" + seller.id)}>
          {t("View Shop.1")}
        </MenuItem>
      </Menu>
    </Card>
  );
}
