import axios from "axios";
import queryString from "query-string";
const baseUrl = "https://fabric-connector.com";
const getToken = () => localStorage.getItem("auth_token");
const rejectUnauthorize = false;

const axiosClient = axios.create({
  baseURL: baseUrl,
  paramsSerializer: (params) => queryString.stringify({ params }),
});

axiosClient.interceptors.request.use(async (config) => {
  return {
    ...config,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
});

export default axiosClient;
