import { Lock } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { FormControl, InputAdornment } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import axiosClient from "../../api/axiosClient";
import { useAuth } from "../../contexts/AuthContext";
import { useMutation, useQueryClient } from "react-query";
import { updateUserInfo } from "../../api/userApi";
import { EditUserFormSchema } from "../schemas";
// TODO: Translation
// TODO: React Query mutation
export default function EditAccountInfo() {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { auth, getAccountInfo } = useAuth();
  const [phone, setPhone] = useState(auth?.phone ?? "");
  const [info, setInfo] = useState<MuiTelInputInfo>();
  const handlePhoneChange = (newPhone: string, info: MuiTelInputInfo) => {
    setPhone(newPhone);
    console.log(newPhone); 
    setInfo(info);
  };
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateUserInfoMutation = useMutation(updateUserInfo,{
    onSuccess:() =>{
      queryClient.invalidateQueries("userInfo")
      enqueueSnackbar("Successfully changed your info", {
        variant: "success",
        preventDuplicate: true,
      });
      setOpen(false);
    },
    onError: () => {
      enqueueSnackbar("Failed to change your info", {
        variant: "error",
        preventDuplicate: true,
      });
    }
    
  })

  return (
    <>
      <Button
        sx={{ textTransform: "none" }}
        variant="contained"
        color="secondary"
        onClick={handleClickOpen}
      >
        Edit Account Info
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Account Info</DialogTitle>
        <DialogContent>
          <Formik
            validationSchema={EditUserFormSchema}
            initialValues={{
              lastName: auth?.lastName ?? "",
              firstName: auth?.firstName ?? "",
              countryCode: auth?.countryCode ?? "",
              phoneNumber: auth?.phone ?? "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              const data = {
                lastName: values.lastName,
                firstName: values.firstName,
                countryCode: info?.countryCode,
                phone: phone,
              };
              

              updateUserInfoMutation.mutate(data);
            }}
          >
            {({ values, errors, isSubmitting, isValidating, submitForm }) => (
              <Form>
                <FormControl fullWidth>
                  <Field
                    component={TextField}
                    autoComplete="on"
                    name="firstName"
                    label={"First Name"}
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Field
                    component={TextField}
                    label={"Last Name"}
                    name="lastName"
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <MuiTelInput value={phone} onChange={handlePhoneChange} />
                </FormControl>

                <LoadingButton
                  variant="contained"
                  fullWidth
                  size="large"
                  loading={isSubmitting || isValidating}
                  sx={{
                    marginTop: "1rem",
                    backgroundColor: "secondary.main",
                    color: "#fff",
                  }}
                  type="submit"
                >
                  {t("Save changes.1")}
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
