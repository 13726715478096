import axiosClient from "./axiosClient";

type GetMessageResponse = {
  content: FCMessage[];
  pageable: {
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
    unpaged: boolean;
    paged: boolean;
  };
  totalPages: number;
  last: boolean;
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  size: number;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
};

export const getReceivedMessages = async () => {
  const response = await axiosClient.get<GetMessageResponse>(
    "api/user/current/messages/received"
  );

  return response.data;
};

export const getSentMessages = async () => {
  const response = await axiosClient.get<GetMessageResponse>(
    "api/user/current/messages/sent"
  );

  return response.data;
};

export const deleteMessage = async (messageId: number) => {
  return await axiosClient.delete(`/api/message/delete/${messageId}/`);
};
